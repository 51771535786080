'use client';

import clsx from 'clsx';
import React from 'react';
import { Button, ButtonProps } from 'antd';
import './instagram-button.css';

export const InstagramButton: React.FC<
  ButtonProps & { transparent?: boolean }
> = ({ className, transparent, ...props }) => {
  return (
    <Button
      className={clsx(
        className,
        'instagram-button',
        transparent && 'transparent',
      )}
      {...props}
    />
  );
};
