import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ServiceListSvg = () => (
  <svg
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      d="M16.76 20.3H12.5C11.672 20.3 11 20.9384 11 21.725C11 22.5116 11.672 23.15 12.5 23.15H14.5002V24.575C14.5002 25.3616 15.1722 26 16.0002 26C16.8282 26 17.5002 25.3616 17.5002 24.575V23.0797C19.4839 22.7454 21 21.1001 21 19.1049C21 16.884 19.0978 15.0769 16.76 15.0769H15.2401C14.5564 15.0769 14.0001 14.5487 14.0001 13.8799C14.0001 13.2302 14.5564 12.7018 15.2401 12.7018H19.5C20.328 12.7018 21 12.0634 21 11.2768C21 10.4902 20.328 9.85002 19.5 9.85002H17.5002V8.42501C17.5002 7.6384 16.8282 7 16.0002 7C15.1722 7 14.5002 7.6384 14.5002 8.42501V9.92038C12.5161 10.2567 11 11.9002 11 13.8971C11 16.1182 12.9022 17.9252 15.24 17.9252H16.76C17.4441 17.9252 18 18.4533 18 19.1219C18 19.7718 17.4441 20.3 16.76 20.3Z"
    />
    <path
      fill="white"
      d="M16.0001 31.9999C24.8219 31.9999 31.9999 24.8219 31.9999 16.0001C31.9999 7.17826 24.8219 0 16.0001 0C7.17826 0 0 7.17826 0 16.0001C0 24.8219 7.17826 31.9999 16.0001 31.9999ZM16.0001 2.18182C23.6189 2.18182 29.8181 8.38105 29.8181 16.0001C29.8181 23.6189 23.6189 29.8181 16.0001 29.8181C8.38105 29.8181 2.18182 23.6189 2.18182 16.0001C2.18182 8.38105 8.38105 2.18182 16.0001 2.18182Z"
    />
  </svg>
);

export const ServiceListIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ServiceListSvg} {...props} />
);
